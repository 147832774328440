<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-row>
            <b-col
              sm="12"
              md="3"
              lg="3"
              xl="3"
            >
              <b-form-group
                label="Năm"
                label-for="year"
              >
                <b-form-input
                  id="year"
                  v-model="filter.year"
                  name="year"
                  type="number"
                  placeholder="Năm"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label="Giảng viên"
                label-for="teacherId"
              >
                <v-select
                  v-model="filter.teacherId"
                  label="name"
                  :options="teachers"
                  :reduce="option => option.id"
                  :disabled="checkTeacher"
                  placeholder="Giảng viên"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
              class="mt-2"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="onSucceed()"
              >
                <span class="text-nowrap text-right">
                  <feather-icon icon="SearchIcon" /> Tìm kiếm
                </span>
              </b-button>
              <b-button
                v-if="creatable"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.teacherHourSaveModal
                class="mr-1"
                variant="success"
                @click="onCreateTeacher"
              >
                <span class="text-nowrap text-right">
                  <feather-icon icon="PlusIcon" /> Thêm giờ giảng
                </span>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="3"
              lg="3"
              xl="3"
            >
              <div class="d-flex justify-content-start flex-wrap">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-success"
                  @click="downloadFile"
                >
                  <span class="text-nowrap text-right">
                    <feather-icon icon="DownloadIcon" /> Xuất báo cáo tổng hợp
                  </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="">
            <b-col cols="12">
              <div class="d-flex align-items-center mb-1 mt-1">
                <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                  đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                </span>
              </div>
              <vue-good-table
                mode="remote"
                :columns="columns"
                :rows="teacherHours"
                :pagination-options="paginationOptions"
                :total-rows="totalRows"
                :line-numbers="true"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'listData'">
                    <b-button
                      v-if="readable"
                      variant="info"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="onListTeacher(props.row)"
                    >
                      <feather-icon icon="ListIcon" />
                    </b-button>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                        đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                      </span>
                    </div>
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị </span>
                      <b-form-select
                        v-model="filter.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="totalRows"
                        :per-page="filter.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <TeacherHourSave
      ref="saveModalRef"
      :item="currentTeacher"
      @succeed="onSucceed"
    />
    <b-modal
      id="modal_teacher_hours"
      ref="modal_teacher_hours"
      centered
      size="xl"
      :title="`Danh sách giờ giảng của giảng viên ${dataSendClass.teacherName} - năm học ${(dataSendClass.year)}`"
      :hide-footer="true"
      no-close-on-backdrop
    >
      <TeacherHourDetail :data-send-class="dataSendClass" />
    </b-modal>
  </b-container>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormGroup, BFormInput, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import TeacherHourSave from '@/views/admin/teacherManagement/tw-teacher-hour/TwTeacherHourSave.vue'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import TeacherHourDetail from '@/views/admin/teacherManagement/tw-teacher-hour/TwTeacherHourDetail.vue'

export default {
  name: 'TeacherHours',
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    TeacherHourDetail,
    vSelect,
    BFormGroup,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    TeacherHourSave,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        year: null,
        teacherId: null,
        sort: null,
      },
      currentTeacher: undefined,
      paginationOptions: {
        enabled: true,
      },
      dataSendClass: {},
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      checkTeacher: true,
    }
  },
  computed: {
    ...mapGetters({
      teachers: 'twTeacherHour/teachers',
      teacherHours: 'twTeacherHour/teacherHoursSum',
      totalRows: 'twTeacherHour/totalRowsSum',
      subjects: 'twTeacherHour/subjects',
      dataExportByYear: 'twTeacherHour/dataExportByYear',
    }),
    columns() {
      const baseColumns = [
        {
          label: 'Tên giảng viên',
          field: 'teacherName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Năm',
          field: 'year',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
      ]
      baseColumns.push(
        {
          label: 'Giờ giảng',
          field: 'teachingHours',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tổng giờ khác',
          field: 'otherTotalHours',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tổng giờ',
          field: 'totalHours',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'listData',
          sortable: false,
          tdClass: 'text-center',
        },
      )
      return baseColumns
    },
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.TW_TEACHER_HOUR)
    },
    readable() {
      return hasPermissionForResource(PermissionCode.READ, ResourceCode.TW_TEACHER_HOUR)
    },
  },
  async created() {
    this.checkTeacher = this.user.teacherId !== null
    this.filter.teacherId = this.user.teacherId !== null ? this.user.teacherId : null
    this.isLoading = true
    try {
      await Promise.all([
        this.readTeachers(),
        this.getSubjects({ organizationId: getUser().orgId }),
        this.getTeacherHoursFromStore(),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      readTeachers: 'twTeacherHour/getTeachers',
      deleteTeacherHours: 'twTeacherHour/deleteTeacherHours',
      readTeacherHours: 'twTeacherHour/readTeacherHoursSum',
      getSubjects: 'twTeacherHour/getSubjects',
      getDataExportByYear: 'twTeacherHour/downloadExportByYear',
    }),
    async downloadFile() {
      if (!this.filter.year) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn năm!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        await this.getDataExportByYear({
          year: this.filter.year,
        })
        const a = document.createElement('a')
        document.body.appendChild(a)
        const blob = new Blob([this.dataExportByYear.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'tong_hop_gio_giang_vien_trong_nam.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onCreateTeacher() {
      this.currentTeacher = undefined
    },
    onListTeacher(teacher) {
      this.dataSendClass = teacher
      this.$refs.modal_teacher_hours.show()
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getTeacherHoursFromStore()
    },
    async getTeacherHoursFromStore() {
      this.isLoading = true
      try {
        await this.readTeacherHours(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getTeacherHoursFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else this.updateParams({ sort: `2_${type}` })
      }
      await this.getTeacherHoursFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ teacherName: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getTeacherHoursFromStore()
    },
    async onSucceed() {
      await this.getTeacherHoursFromStore()
    },
  },
}
</script>
